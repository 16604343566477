<template>
  <div>
    <PageHeaderDisplay
      title="Marketplace"
      sub-title="Various carbon projects which you can purchase to offset your carbon footprint. Click a project to view more information, including accrediations."
      category="Carbon Projects"
      category-logo="fa-trees"
      :category-route="{ name: $route.name }"
    />
    <div class="content">
      <div class="filters">
        <FormGroup id="projectName" v-model="search" label="Name" placeholder="Search..." alt />
        <FormGroup id="projectType" v-model="projectType" label="Type" :options="projectTypeOptions" type="select" placeholder="All" alt />
        <FormGroup id="country" v-model="country" label="Country" :options="countryOptions" type="select" placeholder="All" alt />
      </div>
      <CardList :items="projectCardList">
        <template #title="{ item }">{{ item.shortName }}</template>
        <template #description="{ item }">{{ item.shortDescription }}</template>
        <template #body="{ item }">
          <div class="font-w600 mb-2">
            <i class="fa fa-earth-americas mr-1"></i> {{ item.country }}<span v-if="item.year"> - {{ item.year }}</span>
          </div>
          <div class="d-flex align-items-center">
            <div v-if="item.totalCostPerKgCarbonInUsdCents" class="font-size-lg font-w600 mr-3">
              ${{ (item.totalCostPerKgCarbonInUsdCents * 10) | numberFormat(2) }}/tonne
            </div>
            <button class="btn btn-primary btn-block" @click="constructionModal = true">Add To Cart</button>
          </div>
        </template>
      </CardList>
    </div>
    <ConfirmModal title="Coming Soon" :open="constructionModal" hide-submit @close="constructionModal = false">
      <div class="text-center">
        <i class="fad fa-triangle-person-digging text-primary fa-4x mb-3"></i>
        <p class="font-w600">We are still working on the ability to self-service purchase carbon projects through Etainabl.</p>
        <p>
          In the meantime, please contact us at <a href="mailto:support@etainabl.com">support@etainabl.com</a>, and we can help you purchase a carbon
          project.
        </p>
      </div>
    </ConfirmModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import CardList from '@/components/base/CardList';
import ConfirmModal from '@/components/ConfirmModal';
import FormGroup from '@/components/FormGroup';
import PageHeaderDisplay from '@/components/PageHeaderDisplay';

export default {
  name: 'MarketplaceCarbonList',
  components: {
    CardList,
    ConfirmModal,
    FormGroup,
    PageHeaderDisplay
  },
  data() {
    return {
      loading: true,
      search: '',
      country: null,
      projectType: null,
      constructionModal: false
    };
  },
  computed: {
    ...mapGetters({
      errorAction: 'marketplace/errorAction',
      loadingAction: 'marketplace/loadingAction',
      carbon: 'marketplace/carbon'
    }),
    projectTypeOptions() {
      return [
        { value: '', label: 'All' },
        ...this.carbon.projectTypes.map(projectType => ({
          value: projectType.name,
          label: projectType.name
        }))
      ];
    },
    projectCardList() {
      const projects = [...this.carbon.projects];

      projects.sort((a, b) => a.totalCostPerKgCarbonInUsdCents - b.totalCostPerKgCarbonInUsdCents);

      const filteredProjects = projects
        .filter(item => item.shortName && item.shortDescription && item.totalCostPerKgCarbonInUsdCents > 0)
        .filter(item => item.shortName.toLowerCase().includes(this.search.toLowerCase()))
        .filter(item => (this.projectType ? item.type === this.projectType : true))
        .filter(item => (this.country ? item.country === this.country : true));

      const mappedProjects = filteredProjects.map(item => ({
        ...item,
        link: {
          name: 'marketplace-carbon-view',
          params: { id: item.id }
        }
      }));

      return mappedProjects;
    },
    countryOptions() {
      const countries = [...this.carbon.projects]
        .filter(item => item.shortName && item.shortDescription && item.totalCostPerKgCarbonInUsdCents > 0)
        .map(item => item.country)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map(country => ({
          value: country,
          label: country
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      return [{ value: '', label: 'All' }, ...countries];
    }
  },
  mounted() {
    this.getCarbonProjectTypes();
    this.getCarbonProjects();
  },
  methods: {
    ...mapActions({
      getCarbonProjectTypes: 'marketplace/getCarbonProjectTypes',
      getCarbonProjects: 'marketplace/getCarbonProjects'
    })
  }
};
</script>
<style scoped>
.filters {
  box-sizing: border-box;
  display: grid;
  grid-gap: 15px;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  justify-content: center;
}
</style>
