var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeaderDisplay', {
    attrs: {
      "title": "Marketplace",
      "sub-title": "Various carbon projects which you can purchase to offset your carbon footprint. Click a project to view more information, including accrediations.",
      "category": "Carbon Projects",
      "category-logo": "fa-trees",
      "category-route": {
        name: _vm.$route.name
      }
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "filters"
  }, [_c('FormGroup', {
    attrs: {
      "id": "projectName",
      "label": "Name",
      "placeholder": "Search...",
      "alt": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "projectType",
      "label": "Type",
      "options": _vm.projectTypeOptions,
      "type": "select",
      "placeholder": "All",
      "alt": ""
    },
    model: {
      value: _vm.projectType,
      callback: function callback($$v) {
        _vm.projectType = $$v;
      },
      expression: "projectType"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "country",
      "label": "Country",
      "options": _vm.countryOptions,
      "type": "select",
      "placeholder": "All",
      "alt": ""
    },
    model: {
      value: _vm.country,
      callback: function callback($$v) {
        _vm.country = $$v;
      },
      expression: "country"
    }
  })], 1), _c('CardList', {
    attrs: {
      "items": _vm.projectCardList
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(_vm._s(item.shortName))];
      }
    }, {
      key: "description",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(_vm._s(item.shortDescription))];
      }
    }, {
      key: "body",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "font-w600 mb-2"
        }, [_c('i', {
          staticClass: "fa fa-earth-americas mr-1"
        }), _vm._v(" " + _vm._s(item.country)), item.year ? _c('span', [_vm._v(" - " + _vm._s(item.year))]) : _vm._e()]), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [item.totalCostPerKgCarbonInUsdCents ? _c('div', {
          staticClass: "font-size-lg font-w600 mr-3"
        }, [_vm._v(" $" + _vm._s(_vm._f("numberFormat")(item.totalCostPerKgCarbonInUsdCents * 10, 2)) + "/tonne ")]) : _vm._e(), _c('button', {
          staticClass: "btn btn-primary btn-block",
          on: {
            "click": function click($event) {
              _vm.constructionModal = true;
            }
          }
        }, [_vm._v("Add To Cart")])])];
      }
    }])
  })], 1), _c('ConfirmModal', {
    attrs: {
      "title": "Coming Soon",
      "open": _vm.constructionModal,
      "hide-submit": ""
    },
    on: {
      "close": function close($event) {
        _vm.constructionModal = false;
      }
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('i', {
    staticClass: "fad fa-triangle-person-digging text-primary fa-4x mb-3"
  }), _c('p', {
    staticClass: "font-w600"
  }, [_vm._v("We are still working on the ability to self-service purchase carbon projects through Etainabl.")]), _c('p', [_vm._v(" In the meantime, please contact us at "), _c('a', {
    attrs: {
      "href": "mailto:support@etainabl.com"
    }
  }, [_vm._v("support@etainabl.com")]), _vm._v(", and we can help you purchase a carbon project. ")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }